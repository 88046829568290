import React from "react";
import { Container, ContainerTitle } from "./Container";
import { backgroundColors } from "../../global/colors";
import { Form } from "./Form";
import { ContactSubtitle, ContactTitle } from "../../global/typography";
import { MessageIcon } from "./Icons";
import styled from "@emotion/styled";
import { breakpoints } from "../../global/breakpoints";

const Wrapper = styled.div<{ startColor: string, endColor: string }>`
width: 100%;
background: linear-gradient(180deg, ${({ startColor }) => startColor} 0, ${({ startColor }) => startColor} 92%, ${({ endColor }) => endColor} 100% );
@media(min-width: ${breakpoints.desktop}) {
  padding: 96px 64px;
}

`

export const Message = () => {


  return (
    <Wrapper startColor={backgroundColors.purple[0]} endColor={backgroundColors.purple[1]}>
      <Container id="contact">
        <ContainerTitle>
          <MessageIcon />
          <ContactTitle>
            Quer saber mais?
          </ContactTitle>
          <ContactSubtitle>
            Entre em contato conosco e em breve retornaremos.
          </ContactSubtitle>
        </ContainerTitle>
        <Form />
      </Container>
    </Wrapper>
  )
}
import styled from "@emotion/styled";
import { breakpoints } from "../../global/breakpoints";
import { logoNavbar } from "../../assets"

const LogoTemplate = styled.img`
  width: 106px;
  height: 54px;
  object-fit: contain;
  @media(min-width: ${breakpoints.desktop}) {
    min-width: 120px;
    min-height: 60px;
  }
`

const LogoContainer = styled.div`
display: flex;
align-items: baseline;
cursor: pointer;
`

export const Logo = () => {
  return (
    <LogoContainer onClick={() => window.location.href = "#startScreen"}> <LogoTemplate src={logoNavbar} /></LogoContainer >
  )
}
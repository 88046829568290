import styled from "@emotion/styled";
import React from "react";

export const Button = styled.button<{ width: string, backgroundColor: string, children: React.ReactNode }>`
  height: 48px;
  width: ${({ width }) => width};
  min-width: ${({ width }) => width};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 8px;
  cursor: pointer;
`


import styled from "@emotion/styled";
import { Button } from "@mui/material"
import { CreditsText } from "../../global/typography";
import bendevi from "../../assets/bendevi.png"
import { breakpoints } from "../../global/breakpoints";


const Content = styled(Button)`
background-color: #f2f2f2;
width: fit-content;
display: flex;
text-transform: none;
padding: 16px;
justify-content: center;
align-items: center;
gap: 8px;

`
const BendeviLogo = styled.img`
 width: 16px;
 height: 16px;
 border-radius: 100%;
`
const Wrapper = styled.div`
display:flex;
justify-content: center;
align-items: center;
`

export const Credits = () => {
  return (
    <Content>
      <CreditsText>
        Desenvolvido por:
      </CreditsText>
      <Wrapper>
        <BendeviLogo src={bendevi} />
        <CreditsText>Bendevi</CreditsText>
      </Wrapper>

    </Content>
  )
}
import img1 from "../../assets/promotionCarousel/imagem1.jpg"
import img2 from "../../assets/promotionCarousel/imagem2.jpg"
import img3 from "../../assets/promotionCarousel/imagem3.jpg"
import img4 from "../../assets/promotionCarousel/imagem4.jpg"
import img5 from "../../assets/promotionCarousel/imagem5.jpg"
import img6 from "../../assets/promotionCarousel/imagem6.jpg"
import video from "../../assets/promotionCarousel/video.mp4"


export const ImgList = [img1, img2, img3, img4, img5, img6, video];

export const brandsImgList = [img2, img3, img4, img5, img6, img2];
import adesivoSilicone from "./tytan-adesivo-de-silicone.png"
import pu40 from "./tytan-PU40-multiuso.webp"
import adesivoEspelhos from "./tytan-adesivo-espelhos.png"
import espumaPU from "./tytan-espuma-pu-multiuso.webp"
import fix2gtAdesivo from "./tytan-fix2gt-adesivo.webp"
import selante from "./tytan-selante-base-agua.png"
import { Product } from "../../global/types"

export const list: Product[] = [
  {
    displayName: "Espuma PU multiuso",
    src: espumaPU
  },
  {
    displayName: "Pu 40 multiuso",
    src: pu40
  },
  {
    displayName: "Adesivo de silicone",
    src: adesivoSilicone
  },
  {
    displayName: "Fix2gt selante",
    src: fix2gtAdesivo
  },
  {
    displayName: "Adesivo para espelhos",
    src: adesivoEspelhos
  },
  {
    displayName: "Selante à base d'água",
    src: selante
  }
]

export {
  adesivoSilicone,
  pu40,
  adesivoEspelhos,
  espumaPU,
  fix2gtAdesivo,
  selante
}
import styled from "@emotion/styled"
import { breakpoints } from "../../global/breakpoints"
import { colors } from "../../global"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* min-height: 100vh;  */
  gap: 8px;
  padding: 24px 32px 0px;
  max-width: 1500px;
@media (min-width:${breakpoints.tablet}){
  padding: 48px 64px 0px;
}
@media(min-width: ${breakpoints.desktop}) {
  gap: 24px;
}
`

export const ContainerBrands = styled.div`
  display: flex;
  padding-top: 16px;
  gap: 10px;
  /* grid-template: 1fr 1fr 1fr / 1fr 1fr; */
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media(min-width: ${breakpoints.desktop}){
    /* grid-template: 1fr 1fr / 1fr 1fr 1fr; */
    gap: 20px;
  /* grid-auto-flow: row; */
  max-width: 720px;
  }
`

export const ContainerRow = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  @media(min-width: ${breakpoints.desktop}) {
    width:100%;
    justify-content: center;
    gap:64px;
  }
`

export const ContainerLogo = styled.div <{ index: number }>`
  background-color: #fff;
  display: flex;
  width: 150px;
  height: 75px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 4px #0000001f double;
  cursor: pointer;
  
  @media(min-width: ${breakpoints.desktop}) {
  background-color: #fcfcfcdd;
    border: 2px ${colors.titleText}88 solid;
    height: 84px;
    width: 180px;
    padding: 0;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 4px;
    /* filter: drop-shadow(-4px 4px 4px); */
    :hover {
    background-color: #fffcef;
  }
  }
`

import desempenadeira from "./desempenadeira-inox-marmorato.png"
import roloRespingo from "./rolo-nao-respinga.webp"
import roloRustico from "./rolo-efeito-rustico.png"
import kitRoloRespingo from "./kit-rolo-nao-respinga.png"
import roloCarneiro from "./rolo-pele-de-carneiro.png"
import trinchas from "./trinchas.webp"
import { Product } from "../../global/types"

export const list: Product[] = [
  {
    displayName: "Rolo pele de carneiro",
    src: roloCarneiro
  },
  {
    displayName: "Rolo não respinga",
    src: roloRespingo
  },
  {
    displayName: "Rolo Efeito rústico",
    src: roloRustico
  },
  {
    displayName: "Trinchas",
    src: trinchas
  },
  {
    displayName: "Kit com 3 rolos não respinga",
    src: kitRoloRespingo
  },
  {
    displayName: "Desempenadeira inox marmorato",
    src: desempenadeira
  }
]
export {
  desempenadeira,
  roloRespingo,
  roloCarneiro,
  roloRustico,
  kitRoloRespingo,
  trinchas
}
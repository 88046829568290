import styled from "@emotion/styled";
import { breakpoints } from "../../global/breakpoints";

export const Container = styled.div`
  gap: 32px;
  padding: 64px 32px;
  display: flex;
  flex-direction: column;
  max-width: 1372px;
  margin: 0 auto;
  @media(min-width: ${breakpoints.desktop}){
    padding: 96px 64px;
    border: 4px #fff solid;
    border-radius: 20px;
    background-color: #ffffff28;
  }
`

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
`
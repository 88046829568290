import styled from "@emotion/styled"
import { Navbar } from './components/navbar/Index';
import { StartScreen } from './components/startScreen/Index';
import BackgroudImage from "./assets/background.png";
import { Brands } from "./components/brands";
import { PromotionCarousel } from "./components/promotionCarousel/Index";
import { About } from "./components/about/Index";
import { Footer } from "./components/footer/Index";
import { Message } from "./components/message/Index";

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  ::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${BackgroudImage});
  background-position: center;
  opacity: 0.5;
  z-index: -1;
}
`

function App() {
  return (
    <AppContainer>
      <Navbar />
      <StartScreen />
      <PromotionCarousel />
      <Brands />
      <Message />
      <About />
      <Footer />
    </AppContainer>
  );
}

export default App;

import styled from "@emotion/styled";
import { breakpoints } from "../../global/breakpoints";

export const Image = styled.img`
  border: #0000001f;
  width: 250px;
  height: 150px;

  @media(min-width: ${breakpoints.desktop}) {
    height: 120px;
  }
`
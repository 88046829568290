import styled from "@emotion/styled";
import { breakpoints } from "../../global/breakpoints";

export const Container = styled.div`
  padding: 48px 0;
  background-color: transparent;
  max-width: 1500px;
  @media(min-width: ${breakpoints.tablet}) {
    padding: 48px 64px;
  }
  @media(min-width: ${breakpoints.desktop}) {
    padding: 64px 64px;
    margin: 0 auto;
  }
`

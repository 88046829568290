import styled from "@emotion/styled";
import { backgroundColors } from "../../global/colors";
import { breakpoints } from "../../global/breakpoints";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 64px;
  gap: 8px;
  width: 100%;
  background-color: ${backgroundColors.purple[1]};
  @media(min-width: ${breakpoints.desktop}) {
    
  justify-content: center;
  align-items: center;
  }
`

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width:1372px;
  justify-content: center;
  align-items: center;
  @media(min-width: ${breakpoints.desktop}) {
    justify-content: end;
    align-items: end;
  }
`

export const ContainerContact = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`
import styled from "@emotion/styled";
import { ButtonText, ContactFormText } from "../../global/typography";
import { Button } from "../button";
import { SendIcon } from "./Icons";
import { breakpoints } from "../../global/breakpoints";
import { sendMail } from "../../services/sendMail";
import { colors } from "../../global";
import Alert from '@mui/material/Alert';
import { useState } from "react";
import { Link } from "@mui/material";



const InputText = styled.input`
  display: block;
  height: 27px;
  width: 70%;
  background-color: #F2F2F2;
  border-radius: 5px;
  border: none;
  float: left;
  @media(min-width: ${breakpoints.tablet}){
    width: 85%;
  }
  @media(min-width: ${breakpoints.desktop}){
    width: 90%;
  }
`

const InputTextArea = styled.textarea`
  display: block;
  width: 60%;
  max-width: 70%;
  min-width: 70%;
  border-radius: 5px;
  background-color: #F2F2F2;
  border: none;
  min-height: 128px;
  float: left;
  @media(min-width: ${breakpoints.tablet}){
    width: 85%;
    max-width: 85%;
    min-width: 85%;
  }
  @media(min-width: ${breakpoints.desktop}){
    width: 90%;
    min-width: 90%;
    max-width: 90%;
  }
`

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  inputType: number;
  name: string;
}

const InputGroup = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
`;

const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const ContainerButton = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
`

const Input: React.FC<InputProps> = ({ id, name, label, inputType, ...rest }) => {
  return (
    <InputGroup>
      <ContactFormText htmlFor={id} >{label}</ContactFormText>
      <InputText id={id} name={name} {...rest} />
    </InputGroup>
  );
}


export const Form = () => {
  const [name, setName] = useState("")
  const [subject, setSubject] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [isAlertVisible, toggleAlertVisible] = useState(false)
  const [error, setError] = useState(0)
  const [isButtonDisabled, toggleButtonDisabled] = useState(false)

  const send = async () => {
    toggleButtonDisabled(true)
    const res = await sendMail(name, subject, email, message)

    if (res) {
      setError(2);
      setMessage("")
      setSubject("")
      setName("")
      setEmail("")
    } else {
      setError(1);

    }
    if (!isAlertVisible) toggleAlertVisible(!isAlertVisible);
    toggleButtonDisabled(false)

  }
  return (
    <ContainerForm>
      {isAlertVisible && error === 2 && <Alert onClose={() => setError(0)} severity="success">Email enviado com sucesso</Alert>}
      {isAlertVisible && error === 1 && <Alert onClose={() => setError(0)} severity="error">Houve um problema no envio, verifique os dados inseridos e tente novamente. Caso o problema persista entre em contato pelo <Link target="_blank" href={`https://wa.me/+5521969941685/?text=${encodeURI("Olá, gostaria de saber mais sobre a L Amado representações comerciais e consultar a disponibilidade na minha região.")}`}>Whatsapp</Link></Alert>}
      <Input disabled={isButtonDisabled} id="name" name="name" type="text" inputType={0} label="Nome: " onChange={(e) => setName(e.target.value)} value={name} />
      <Input disabled={isButtonDisabled} id="subject" name="subject" type="text" inputType={0} label="Assunto: " onChange={(e) => setSubject(e.target.value)} value={subject} />
      <Input disabled={isButtonDisabled} id="email" name="email" type="text" inputType={0} label="Email: " onChange={(e) => setEmail(e.target.value)} value={email} />
      <InputGroup>
        <ContactFormText htmlFor="message" >Mensagem: </ContactFormText>
        <InputTextArea disabled={isButtonDisabled} name={name} value={message} onChange={(e) => setMessage(e.target.value)} />
      </InputGroup>
      <ContainerButton>
        <Button disabled={isButtonDisabled} style={{ cursor: isButtonDisabled ? "wait" : "pointer" }} onClick={send} backgroundColor={isButtonDisabled ? "#c0c0c0" : colors.buttonColors.green} width="199px"><ButtonText>Enviar</ButtonText> <SendIcon /></Button>
      </ContainerButton>
    </ContainerForm>
  )

}
import adesivoLaminacao from "./Adesivo-de-Laminação.webp"
import massaPolir from "./Massa-de-polir-N-2-0-450gr-Base-agua.webp"
import massaCorrecoes from "./Massa-para-pequenas-correcoes.webp"
import massaPoliester from "./Massa-poliéster-Megalight.webp"
import pretoFosco from "./Preto-fosco-vinilico-c-catal.webp"
import primerPU from "./Primer-Pu-511-CinzaBranco.webp"
import { Product } from "../../global/types"

export const list: Product[] = [
  {
    displayName: "Massa poliéster Megalight",
    src: massaPoliester
  },
  {
    displayName: "Massa de polir à base d'água",
    src: massaPolir
  },
  {
    displayName: "Massa para pequenas correções",
    src: massaCorrecoes
  },
  {
    displayName: "Preto fosco vinílico com catalisador",
    src: pretoFosco
  },
  {
    displayName: "Adesivo de laminação",
    src: adesivoLaminacao
  },
  {
    displayName: "Primer PU",
    src: primerPU
  }
]
export {
  adesivoLaminacao,
  massaPolir,
  massaCorrecoes,
  massaPoliester,
  pretoFosco,
  primerPU
}
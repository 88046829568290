
export const SendIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.67496 18.1211L19.1666 10.6211L1.67496 3.12109L1.66663 8.95443L14.1666 10.6211L1.66663 12.2878L1.67496 18.1211Z" fill="#F2F2F2" />
    </svg>
  )
}

export const MessageIcon = () => {
  return (
    <svg width="120" height="96" viewBox="0 0 120 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M108 0H12C5.4 0 0.0599999 5.4 0.0599999 12L0 84C0 90.6 5.4 96 12 96H108C114.6 96 120 90.6 120 84V12C120 5.4 114.6 0 108 0ZM102 84H18C14.7 84 12 81.3 12 78V24L53.64 50.04C57.54 52.5 62.46 52.5 66.36 50.04L108 24V78C108 81.3 105.3 84 102 84ZM60 42L12 12H108L60 42Z" fill="#F8F8F8" />
    </svg>
  )
}
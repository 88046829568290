export const brands = {
  sinteplast: [
    "Com mais de 65 anos de tradição, a Sinteplast é sinônimo de inovação e qualidade nos setores arquitetônico, industrial, de construção e automotivo.",
    "A linha Recubriplast é refêrencia em impermeabilizantes e possui soluções para as mais variadas superfíces, composta por produtos que combinam resistência e facilidade de aplicação, essa linha é ideal para diversas superfícies, garantindo acabamentos de qualidade e com durabilidade."
  ],
  sulan: [
    "Há mais de 20 anos no mercado, este fabricante brasileiro de Tintas e Vernizes se destaca pela alta qualidade de seus produtos.",
    "Oferecendo linhas Premium, Standard e Econômica, é sinônimo de confiança e excelência no setor de tintas imobiliárias."
  ],
  2001: [
    "Com mais de 25 anos de mercado, a 2001 é referência em produtos para reparação automotiva. ",
    "Reconhecida pela inovação e qualidade, a marca oferece soluções tecnológicas que atendem às demandas do Brasil e da América Latina."
  ],
  tytan: [
    "A Tytan Professional oferece soluções inovadoras para construção e acabamento, como Espumas de Poliuretano, Selantes e Adesivos. ",
    "Com foco em durabilidade e segurança, seus produtos são reconhecidos mundialmente pela eficiência e alta qualidade"
  ],
  castor: [
    "A Castor, com 47 anos de mercado, é referência em Ferramentas para Pintura e Acabamento.",
    "Aliando inovação e sustentabilidade para soluções de alta qualidade, atendendo o Brasil e América Latina."
  ],
}
import esmalteSintetico from "./Esmalte-Sintetico-brilhante.webp"
import fundoPreparador from "./fundo-preparador.jpg"
import massaAcrilica from "./Massa-Acrílica-balde-25kg.webp"
import massaCorrida from "./Massa-corrida-Pva-Balde.webp"
import texturaRustica from "./Textura-rustica.jpg"
import tintasSulanplast from "./Tintas-sulanplast.png"
import { Product } from "../../global/types"

export const list: Product[] = [
  {
    displayName: "Massa acrílica 25kg",
    src: massaAcrilica
  },
  {
    displayName: "Fundo preparador",
    src: fundoPreparador
  },
  {
    displayName: "Esmalte sintético brilhante",
    src: esmalteSintetico
  },
  {
    displayName: "Textura rústica",
    src: texturaRustica
  },
  {
    displayName: "Massa corrida PVA",
    src: massaCorrida
  },
  {
    displayName: "Tinta Sulanplast",
    src: tintasSulanplast
  }
]


export {
  esmalteSintetico,
  fundoPreparador,
  massaAcrilica,
  massaCorrida,
  texturaRustica,
  tintasSulanplast
}
import { Container, ContainerContent } from "./Container"

import { Credits } from "../credits"



export const Footer = () => {
  return (
    <Container>
      <ContainerContent>
        <Credits />
      </ContainerContent>
    </Container>
  )
}
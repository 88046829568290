import styled from "@emotion/styled";
import { breakpoints } from "../../global/breakpoints";

export const ContainerAbout = styled.div<{ startColor: string, endColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px 24px 64px 24px;
  gap: 32px;
  flex-direction: column;
  max-width: 1372px;
  @media(min-width:${breakpoints.tablet}) {
    padding: 64px 32px 64px 32px;
  }
  @media(min-width:${breakpoints.desktop}) {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    gap: 64px;
    padding: 0;
  }
`

export const ContainerImgText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  @media(min-width:${breakpoints.desktop}) {
    gap: 36px;
    justify-content: flex-start;
    align-items: flex-start;
  }
`

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px 0px;
  @media(min-width:${breakpoints.desktop}){
    gap: 24px;
    padding-top: 32px;
    padding-left: 32px;
    padding-bottom: 0;
    border-left: 4px #f2f2f2 solid;
    border-top: 4px #f2f2f2 solid;
    height: fit-content;
    border-radius: 20px 0 0 0;
    
  }
`
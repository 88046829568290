export const sendMail = async (name: string, subject: string, email: string, message: string) => {
  const res = await fetch("https://yaepyvwltihjsrulmmib.supabase.co/functions/v1/email", {
    method: "POST", body: JSON.stringify({
      name,
      subject,
      email,
      message
    })
  });
  return res.status === 200 ? true : false;
}

export const MailIcon = () => {
  return (
    <svg width="19" height="16" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 0.5H2C0.9 0.5 0.00999999 1.4 0.00999999 2.5L0 14.5C0 15.6 0.9 16.5 2 16.5H18C19.1 16.5 20 15.6 20 14.5V2.5C20 1.4 19.1 0.5 18 0.5ZM18 4.5L10 9.5L2 4.5V2.5L10 7.5L18 2.5V4.5Z" fill="white" />
    </svg>

  );
};

export const WhattsappIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.75 11.96C15 12.09 15.16 12.16 15.21 12.26C15.27 12.37 15.25 12.87 15 13.44C14.8 14 13.76 14.54 13.3 14.56C12.84 14.58 12.83 14.92 10.34 13.83C7.85001 12.74 6.35001 10.08 6.23001 9.91C6.11001 9.74 5.27001 8.53 5.31001 7.3C5.36001 6.08 6.00001 5.5 6.26001 5.26C6.50001 5 6.77001 4.97 6.94001 5H7.41001C7.56001 5 7.77001 4.94 7.96001 5.45L8.65001 7.32C8.71001 7.45 8.75001 7.6 8.66001 7.76L8.39001 8.17L8.00001 8.59C7.88001 8.71 7.74001 8.84 7.88001 9.09C8.00001 9.35 8.50001 10.18 9.20001 10.87C10.11 11.75 10.91 12.04 11.15 12.17C11.39 12.31 11.54 12.29 11.69 12.13L12.5 11.19C12.69 10.94 12.85 11 13.08 11.08L14.75 11.96ZM10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7363 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.03001 20 6.20001 19.43 4.65001 18.45L6.42913e-06 20L1.55001 15.35C0.535511 13.7502 -0.00214461 11.8944 6.42913e-06 10C6.42913e-06 7.34784 1.05357 4.8043 2.92894 2.92893C4.8043 1.05357 7.34784 0 10 0ZM10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84286 5.84344 2.00001 7.87827 2.00001 10C2.00001 11.72 2.54001 13.31 3.46001 14.61L2.50001 17.5L5.39001 16.54C6.73779 17.4935 8.34902 18.0038 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1572 14.1566 18 12.1217 18 10C18 7.87827 17.1572 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2Z" fill="white" />
    </svg>
  );
};

export const InstagramIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.8 0H14.2C17.4 0 20 2.6 20 5.8V14.2C20 15.7383 19.3889 17.2135 18.3012 18.3012C17.2135 19.3889 15.7383 20 14.2 20H5.8C2.6 20 0 17.4 0 14.2V5.8C0 4.26174 0.61107 2.78649 1.69878 1.69878C2.78649 0.61107 4.26174 0 5.8 0ZM5.6 2C4.64522 2 3.72955 2.37928 3.05442 3.05442C2.37928 3.72955 2 4.64522 2 5.6V14.4C2 16.39 3.61 18 5.6 18H14.4C15.3548 18 16.2705 17.6207 16.9456 16.9456C17.6207 16.2705 18 15.3548 18 14.4V5.6C18 3.61 16.39 2 14.4 2H5.6ZM15.25 3.5C15.5815 3.5 15.8995 3.6317 16.1339 3.86612C16.3683 4.10054 16.5 4.41848 16.5 4.75C16.5 5.08152 16.3683 5.39946 16.1339 5.63388C15.8995 5.8683 15.5815 6 15.25 6C14.9185 6 14.6005 5.8683 14.3661 5.63388C14.1317 5.39946 14 5.08152 14 4.75C14 4.41848 14.1317 4.10054 14.3661 3.86612C14.6005 3.6317 14.9185 3.5 15.25 3.5ZM10 5C11.3261 5 12.5979 5.52678 13.5355 6.46447C14.4732 7.40215 15 8.67392 15 10C15 11.3261 14.4732 12.5979 13.5355 13.5355C12.5979 14.4732 11.3261 15 10 15C8.67392 15 7.40215 14.4732 6.46447 13.5355C5.52678 12.5979 5 11.3261 5 10C5 8.67392 5.52678 7.40215 6.46447 6.46447C7.40215 5.52678 8.67392 5 10 5ZM10 7C9.20435 7 8.44129 7.31607 7.87868 7.87868C7.31607 8.44129 7 9.20435 7 10C7 10.7956 7.31607 11.5587 7.87868 12.1213C8.44129 12.6839 9.20435 13 10 13C10.7956 13 11.5587 12.6839 12.1213 12.1213C12.6839 11.5587 13 10.7956 13 10C13 9.20435 12.6839 8.44129 12.1213 7.87868C11.5587 7.31607 10.7956 7 10 7Z" fill="white" />
    </svg>
  )
}
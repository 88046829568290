import styled from "@emotion/styled";
import { NavMenuText, NavMenuTextLight } from "../../global/typography";
import React, { useEffect, useRef, useState } from "react";
import { none } from "@cloudinary/url-gen/qualifiers/fontHinting";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import WorkspacePremiumRoundedIcon from '@mui/icons-material/WorkspacePremiumRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { colors } from "../../global";
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import { breakpoints } from "../../global/breakpoints";

const StoreIcon = styled(ShoppingCartTwoToneIcon)`
fill: currentColor;
`

const MenuTemplate = styled.ul<{ visible: boolean, children: React.ReactNode }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${({ visible }) => (visible ? '64px' : '-314px')};
  z-index: 80;
  border-bottom: 1px #0000001f solid ;
  width: 100%;
  transition: 800ms;
  filter: drop-shadow(2px 2px 2px);
  @media(min-width: ${breakpoints.desktop}) {
    display: none;
  }
`

const BrandContainer = styled.div<{ height: string }>`
  display: flex;
  max-height: ${(({ height }) => height)};
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  transition: max-height 500ms linear;
  overflow: hidden;
`

const MenuBrandContainer = styled.ul<{ height: string }>`
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  max-height: ${(({ height }) => height)};
  transition: max-height 500ms linear;
  overflow: hidden;
`

const NavMenuItem = styled.li <{ bgColor: string }>`
padding: 18px 64px;
border-bottom: 1px #0000001f solid;
width: 100%;
background-color: ${(({ bgColor }) => bgColor)};
`

const NavMenuTitleContainer = styled.div<{ height: string }>`
padding: 18px 32px;
width: 100%;
border-bottom: 1px #0000001f solid;
display: flex;
align-items: center;
justify-content: space-between;
background-color: #f7f7f7;
max-height: ${(({ height }) => height)};
`

const GroupTemplate = (props: { title: string, brands: { name: string, id: string }[], className?: string, isVisible: boolean, toggle: any }) => {
  const [brandsVisible, toggleBrandsVisible] = useState(false)
  useEffect(() => {
    if (!props.isVisible) toggleBrandsVisible(false);
  })

  return (
    <MenuBrandContainer height={props.isVisible ? "390px" : "0px"} className={props.className}>
      <NavMenuTitleContainer onClick={() => toggleBrandsVisible(!brandsVisible)} height={props.isVisible ? "390px" : "0px"}>
        <NavMenuText>{props.title}</NavMenuText><ExpandMoreRoundedIcon />
      </NavMenuTitleContainer>
      <BrandContainer height={brandsVisible && props.isVisible ? "200px" : "0px"}>
        {props.brands.map((brand) => {
          return (
            <NavMenuItem bgColor="#ffffff" onClick={() => { window.location.href = brand.id; props.toggle(false); }}>
              <NavMenuText>- {brand.name}</NavMenuText>
            </NavMenuItem>
          )
        })}
      </BrandContainer>
    </MenuBrandContainer>
  )
}

const ListItem = styled.li<{ bgColor: string, hideBorder?: boolean }>`
border-bottom: ${(({ hideBorder }) => hideBorder ? "none" : "1px #0000001f solid")};
padding: 18px 32px;
display: flex;
align-items: center;
gap: 8px;
background-color: ${(({ bgColor }) => bgColor)};
`

const SendIcon = styled(SendRoundedIcon)`
  fill: ${colors.lightText};
`

export const Menu = (props: { visible: boolean, toggle: any }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [brandsVisible, toggleBrandsVisible] = useState(false)
  const [height, setHeight] = useState("0px")

  useEffect(() => {
    if (!props.visible) toggleBrandsVisible(false);
  })
  useEffect(() => {
    if (props.visible && containerRef.current) {
      const scrollHeight = containerRef.current.scrollHeight;
      setHeight(`${scrollHeight}px`);

      const timeout = setTimeout(() => {
        setHeight('auto');
      }, 800);

      return () => clearTimeout(timeout);
    } else {
      setHeight('0px');
    }
  }, [props.visible]);
  return (
    <MenuTemplate visible={props.visible} style={{ cursor: "pointer" }}>
      <ListItem bgColor="#ececec" onClick={() => toggleBrandsVisible(!brandsVisible)}><WorkspacePremiumRoundedIcon style={{ fill: colors.menuText }} /><NavMenuText>Marcas</NavMenuText></ListItem>
      <GroupTemplate brands={[{ name: "Sinteplast (Recubriplast)", id: "#sinteplast" }, { name: "Sulan", id: "#sulan" }, { name: "2001", id: "#2001" }]} title="Grupo sinteplast" isVisible={brandsVisible && props.visible} toggle={props.toggle} />
      <GroupTemplate brands={[{ name: "Tytan", id: "#tytan" }, { name: "Castor", id: "#castor" }]} title="Outras marcas" isVisible={brandsVisible && props.visible} toggle={props.toggle} />
      <ListItem bgColor="#ececec" onClick={() => { window.open("https://shop.lamadorepresentacoes.com.br", "_blank"); props.toggle(false); }}><StoreIcon style={{ fill: colors.menuText }} /><NavMenuText>Loja</NavMenuText></ListItem>
      <ListItem bgColor="#ececec" onClick={() => { window.location.href = "#about"; props.toggle(false); }}><InfoRoundedIcon style={{ fill: colors.menuText }} /><NavMenuText>Sobre</NavMenuText></ListItem>
      <ListItem hideBorder={true} bgColor={colors.buttonColors.purple} onClick={() => { window.location.href = "#contact"; props.toggle(false); }}><SendIcon /><NavMenuTextLight>Envie uma mensagem</NavMenuTextLight></ListItem>
    </MenuTemplate>
  )
}
import styled from "@emotion/styled"
import { colors } from "../../global";
import { breakpoints } from "../../global/breakpoints";


export const Container = styled.div`
  display: flex;
  height: 64px;
  justify-content: space-between;
  padding: 0px 32px;
  align-items: center;
  background-color: ${colors.white};
  position: relative;
  top: 0;
  z-index: 9999;
  border-bottom: 1px #0000001f solid;
  max-width: 1500px;
  
  @media(min-width: ${breakpoints.desktop}) {
    padding: 0px 64px 0px 64px;
    height: 72px;
    margin: 0 auto;
  }
`;
import styled from "@emotion/styled"
import * as colors from "./colors"
import { breakpoints } from "./breakpoints"

export const Title = styled.h1`
  font-size: 26px;
  line-height: 1.71em;
  letter-spacing: 0.03em;
  color: ${colors.titleText};
  text-align: center;
  font-weight: 900;
  font-family: Montserrat, sans-serif;
  @media (min-width:801px){
    font-size: 36px;
    text-align: left;

  }
  @media(min-width: ${breakpoints.desktop}) {
  }
`

export const Subtitle = styled.p`
  line-height: 1.475em;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  color: #666666;
  font-family: Calibri, sans-serif;
  @media (min-width:${breakpoints.tablet}){
    font-size: 20px;
    
  }

  @media(min-width: ${breakpoints.desktop}) {
    color: #333333;
    text-align: left;
  }
`

export const HomeBrandText = styled.p`
  line-height: 1.475em;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  color: ${colors.titleText};
  font-family: Calibri, sans-serif;
  @media (min-width:801px){
    font-size: 18px;
    /* font-weight: 500; */
  }
  @media(min-width: ${breakpoints.desktop}) {
    /* font-weight: 600; */
    font-size: 20px;
    letter-spacing: 1px;
  }
`

export const BrandTitle = styled.h3`
  font-family: Cabin, sans-serif;
  font-weight: 600;
  font-size: 26px;
  letter-spacing: 0.1em;
  color: ${colors.darkText};
`

export const BrandText = styled.p`
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.8em;
  letter-spacing: 0.04em;
  text-align: center;
  color: ${colors.darkText};
  @media(min-width: ${breakpoints.desktop}){
    text-align: left;
  }
`

export const ProductText = styled.p`
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: ${colors.darkText};
  opacity: 80%;
`

export const ContactTitle = styled.h3`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: ${colors.lightText};
`

export const ContactSubtitle = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: ${colors.lightText};
`

export const ContactFormText = styled.label`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  color: ${colors.lightText};
  @media(min-width: ${breakpoints.desktop}){
    font-size: 16px;
  }
`

export const AboutText = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.94em;
  letter-spacing: 0.1em;
  text-align: center;
  color: ${colors.lightText};
  @media(min-width: ${breakpoints.tablet}){
    
  }
  @media(min-width: ${breakpoints.desktop}){
    font-size: 18px;
    line-height: 2.088em;
    text-align: left;
  }
`

export const SocialMediaText = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: ${colors.lightText};
  @media(min-width: ${breakpoints.desktop}){
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
  }
`

export const CreditsText = styled.p`
  font-family: Roboto, sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  color: ${colors.menuText};
  @media(min-width: ${breakpoints.desktop}){
    font-size: 14px;
  }
`

export const NavbarText = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: ${colors.menuText};
  letter-spacing: 1px;
  @media(min-width: ${breakpoints.desktop}) {
    font-size: 16px;
    font-weight: 700;
    font-family: Roboto, sans-serif;
    color: currentColor;
  } 
`

export const NavMenuText = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 18px;
  color: ${colors.menuText};
  font-weight: 500;
  letter-spacing: 1px;
`
export const NavMenuTextLight = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 18px;
  color: ${colors.lightText};
  font-weight: 500;
  letter-spacing: 1px;

`

export const ButtonText = styled.p`
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: ${colors.lightText};
`


export const NavButtonText = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: ${colors.lightText};
  position: relative;
  top: 2px;
`

export const HomeActionText = styled.p`
font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #555555;
  letter-spacing: 1px;
  @media(min-width: ${breakpoints.desktop}) {
    font-size: 16px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    text-align: left;
  } 
`